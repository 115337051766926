import { OsFileUploadOptions } from '@/components/os-file-upload/os-file-upload';
import { ElUploadInternalFileDetail, HttpRequestOptions } from 'element-ui/types/upload';
import { Component, Vue } from 'vue-property-decorator';
import MiniResetPassword from '@/views/reset-password/mini-reset-password/mini-reset-password.vue';
import { UserModule } from '@/store/modules/user';
import { Message } from 'element-ui';
import { messageError, translation } from '@/utils';
import { minioService } from '@/api';
import { MinIOFile } from '@/api/minio';
import { ElForm } from 'element-ui/types/form';
import { emailReg } from '@/utils/validate';
@Component({
  components: { MiniResetPassword }
})
export default class AccountSetting extends Vue {
  public submitLoading = false;

  public uploadOptions: OsFileUploadOptions = {
    action: '',
    accept: '.jpg,.jpeg,.png',
    maxSize: 5,
    uploadLoading: false,
    autoUpload: true,
    showFileList: false,
    hiddenUploadTip: true,
    httpRequest: this.setTodoUploadFile,
    onChange: this.onUploadChange
  };

  public accountForm = {
    email: '',
    mobile: '',
    avatar: ''
  };

  public formRules = {
    email: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(this.$t('accountSetting.inputEmail') as string));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (!emailReg(value)) {
            callback(new Error(this.$t('resetPassword.emailError') as string));
            return;
          }
          callback();
        },
        trigger: ['blur', 'change']
      }
    ],
    mobile: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(this.$t('accountSetting.inputPhone') as string));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  /**
   * 头像预览
   */
  public previewAvatar = '';

  private todoUploadFile?: MinIOFile;

  private bucketName = 'public';

  public created(): void {
    this.accountForm.email = UserModule.email;
    this.accountForm.avatar = UserModule.avatar;
    this.accountForm.mobile = UserModule.mobile;
    this.previewAvatar = this.accountForm.avatar;
  }

  public submit(): void {
    (this.$refs.accountSettingForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return false;
      }
      this.submitLoading = true;
      try {
        // 如果有更换头像文件，则上传并替换头像路径
        if (this.todoUploadFile) {
          await minioService.init();
          const savePath = `avatar/platform/${UserModule.account}`;
          await minioService.uploadByStream(this.todoUploadFile, savePath);
          this.accountForm.avatar = `http://${minioService.endPoint}/${this.bucketName}/${savePath}/${this.todoUploadFile.name}`;
        }

        await UserModule.EditUserInfoAction(this.accountForm);
        Message.success(translation('operationRes.editSuccess'));
      } catch (error) {
        messageError(error);
      } finally {
        this.submitLoading = false;
      }
    });
  }

  public async setTodoUploadFile(requestOptions: HttpRequestOptions): Promise<void> {
    this.todoUploadFile = {
      name: `${new Date().getTime()}_${requestOptions.file.name}`,
      stream: Buffer.from(await requestOptions.file.arrayBuffer()),
      bucketName: this.bucketName,
      metadata: { 'Content-Type': requestOptions.file.type }
    };
  }

  public resetPassword(): void {
    UserModule.setResetPassDialog(true);
  }

  private onUploadChange(file: ElUploadInternalFileDetail, fileList: ElUploadInternalFileDetail[]): void {
    if (fileList.length > 1) {
      fileList.splice(0, 1);
    }
    this.previewAvatar = URL.createObjectURL(file.raw);
  }
}
